<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Aggiungi zona geografica</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'suppliers.zones' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <ZoneForm :feedback.sync="feedback" @submitForm="saveZone" />
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import ZoneForm from '@/views/components/Form/ZoneForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    ZoneForm,
  },
  data () {
    return {
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'suppliers.zones' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveZone (zoneForm) {
      const zone = { ...zoneForm };

      this.$api.createZone(zone)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
};

</script>
